<template>
  <div class="card card-custom gutter-b p-5" style="height: 90%">
    <h2 class="d-flex justify-content-center">Petunjuk Test</h2>
    <div class="my-5">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa, vero iste?
      Impedit esse at vero? Voluptatum at laboriosam facilis nesciunt nemo,
      recusandae porro, consectetur assumenda soluta pariatur sint libero ipsam!
    </div>
    <div class="d-flex justify-content-center mt-10">
      <!-- Nanti kayaknya tambahin fungsi biar kalau udah selesai tombol mulainya di disable -->

      <!-- <b-button to="/posttest"> Mulai </b-button> -->
      <p style="color: rgb(23, 162, 184)">
        Terima kasih telah mengerjakan test yang diselenggarakan
      </p>
    </div>

    <div
      class="col mt-5"
      style="
        display: flex;
        justify-content: center;
        position: absolute;
        top: 60%;
      "
    >
      <div class="table-responsive-sm">
        <table
          id="example"
          class="table table-striped table-bordered"
          style="width: 100%; height: 20%"
        >
          <thead>
            <tr>
              <th style="width: 20px">Percobaan</th>
              <th>Status</th>
              <th style="vertical-align: middle; text-align: center">Nilai</th>
              <th
                style="width: 150px; vertical-align: middle; text-align: center"
              >
                Aksi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in nilai" :key="row.id">
              <td style="vertical-align: middle">
                {{ row.id }}
              </td>
              <td style="vertical-align: middle">
                {{ row.status }}
              </td>
              <td style="vertical-align: middle; text-align: center">
                {{ row.nilai }} / 100
              </td>
              <td style="vertical-align: middle; text-align: center">
                <button style="color: rgb(23, 162, 184)">Review</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

  
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "petunjukpost",
  data() {
    return {
      nilai: [
        { id: 1, percobaan: "Percobaan 1", status: "Selesai", nilai: 90 },
      ],
    };
  },
  methods: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "" }]);
  },
};
</script>